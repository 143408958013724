import {useNavigate} from "react-router-dom";
import React from "react";
import {Button, Modal} from "react-bootstrap";
import ModalClose from "../../../../assets/modal_close.png";

const AllLessonsCompletedModal = ({ handleClose, masterClass }) => {

    const navigate = useNavigate();

    const closeModal = () => {
        handleClose();
    };

    const handleContinue = () => {
        navigate(`/master-classes/${masterClass.id}`);
    };

    return (
        <>
            <Modal onHide={closeModal} centered className="resources-file-uploaded-popup" show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="modal-header m-0">
                    <div className="question">Congratulations!</div>
                    <div className="modal-close" onClick={() => closeModal()}>
                        <img src={ModalClose} alt="Close" />
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{maxHeight: "450px", overflowY: "scroll"}}
                >
                    <div className="row mt-3 px-3">
                        <span className="fs-5 fs-lg-4">
                            You have completed all the lessons of the {masterClass.name} Masterclass. Click continue to rate and complete this masterclass.
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary add-btn"
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AllLessonsCompletedModal;