import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { faqServices } from "../../../services/faqService/FaqServices";
import Loader from "../../../components/common/Loader";
import "./faq.css";
import YoutubeSession from "../../dashboard/components/YoutubeSession";
import RecordedVideoSession from "../../dashboard/components/RecordedVideoSession";

const FAQ = ({ category }) => {
    const limit = 10;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [faqData, setFaqData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event, value) => {
        setPage(value);
    };
    const [showDescription, setShowDescription] = useState(-1);

    const allFaqData = () => {
        setIsLoading(true);
        faqServices.getFaqs(page, limit, category).then((response) => {
            setFaqData(response?.data?.data);
            setTotal(Math.ceil(response?.data?.total_records / limit));
            setIsLoading(false);
        });
    };

    useEffect(() => {
        allFaqData();
    }, [page]);

    useEffect(() => {
        allFaqData();
    }, [category]);

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                faqData.map((item, f) => {
                    return (
                        <div className="faq-section">
                            <div className="mt-4">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="acc-button" onClick={() => setShowDescription(f == showDescription ? -1 : f)}>
                                            <div className="accordion-title">{renderAnswer(item?.title)}</div>
                                            <div style={{ marginLeft: "10px" }}>
                                                {showDescription == f ? (
                                                    <>
                                                        <AiOutlineMinus />{" "}
                                                    </>
                                                ) : (
                                                    <>
                                                        <AiOutlinePlus />{" "}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </h2>
                                    {showDescription == f && (
                                        <div className="accordion-collapse collapse show">
                                            <div className="accordion-body mb-3">{renderAnswer(item?.description)}</div>
                                            {item?.video_url ? (
                                                <YoutubeSession video={item?.video_url} />
                                            ) : item?.full_video_link ? (
                                                <RecordedVideoSession video={item?.full_video_link} />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr />
                        </div>
                    );
                })
            )}

            {total > 0 && (
                <div className="pagination-div">
                    <Pagination count={total} page={page} onChange={handleChange} />
                </div>
            )}
        </>
    );
};
export default FAQ;
