import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import "./Calendar.css";
import { schedularService } from "../../services/schedularCalendarService/SchedularCalendarService";
import ShowAllEventsDetails from "./Modal/ShowAllEventsDetails";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useSelector} from "react-redux";
import {userTypes} from "../../utils/data/userTypes";
import {useNavigate} from "react-router-dom";

const Calendar = () => {
    const [showCalendar, setShowCalendar] = useState(true);
    const [availabilityData, setavailabilityData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const navigate = useNavigate();

    const getAllSpeakerAvailabilityData = () => {
        schedularService.getCalendarData().then((response) => {
            setavailabilityData(response?.data?.calendar);
        });
    };

    useEffect(() => {
        getAllSpeakerAvailabilityData();
    }, []);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setIsPopupOpen(true);
    };

    const initialValue = dayjs(new Date());

    // Add media queries for mobile view
    const styles = {
        circle: {
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            color: "white",
        },
    };

    // Media query for mobile view
    const mobileStyles = {
        circle: {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            color: "white",
        },
    };

    const isMobile = useMediaQuery("(max-width:1200px)"); // Adjust the breakpoint as needed
    const circleStyles = isMobile ? mobileStyles.circle : styles.circle;

    function ServerDay(props) {
        const { day, outsideCurrentMonth, ...other } = props;

        const date = day.format("YYYY-MM-DD");

        // Poc Events Details
        const pocSpecialEvents = availabilityData?.POC?.special_events || [];
        const pocPrevSessionsEvents = availabilityData?.POC?.sessions_details?.institution_key_notes || [];
        const pocCompSessionsEvents = availabilityData?.POC?.sessions_details?.completed_key_notes || [];
        const pocMeetingsEventsSpeaker = availabilityData?.POC?.poc_speaker_meeting_details || [];
        const pocMeetingsEventsSuccessCoach = availabilityData?.POC?.poc_success_coach_meting_details || [];

        const pocSpecialEventsForDate = pocSpecialEvents.filter((event) => event.start_date.includes(date));
        const pocPrevSessionsEventsForDate = pocPrevSessionsEvents.filter((event) => event.start_date.includes(date));
        const pocCompSessionsEventsForDate = pocCompSessionsEvents.filter((event) => event.start_date.includes(date));
        const pocMeetingsEventsSpeakerForDate = pocMeetingsEventsSpeaker.filter((event) => event.start_date.includes(date));
        const pocMeetingsEventsSuccessCoachForDate = pocMeetingsEventsSuccessCoach.filter((event) => event.start_date.includes(date));

        const hasMultipleEventsPoc =
            pocSpecialEventsForDate.length +
                pocPrevSessionsEventsForDate.length +
                pocCompSessionsEventsForDate.length +
                pocMeetingsEventsSpeakerForDate.length +
                pocMeetingsEventsSuccessCoachForDate.length >
            1;

        const hasSingleEventPoc =
            pocSpecialEventsForDate.length +
                pocPrevSessionsEventsForDate.length +
                pocCompSessionsEventsForDate.length +
                pocMeetingsEventsSpeakerForDate.length +
                pocMeetingsEventsSuccessCoachForDate.length ===
            1;

        //Speaker Events Details
        const speakerPrevSessionsEvents = availabilityData?.Speaker?.sessions_details?.institution_key_notes || [];
        const speakerCompSessionsEvents = availabilityData?.Speaker?.sessions_details?.completed_key_notes || [];
        const speakerMeetingsEventsPoc = availabilityData?.Speaker?.speaker_availability?.booked || [];

        const speakerPrevSessionsEventsForDate = speakerPrevSessionsEvents.filter((event) => event.start_date.includes(date));
        const speakerCompSessionsEventsForDate = speakerCompSessionsEvents.filter((event) => event.start_date.includes(date));
        const speakerMeetingsEventsPocForDate = speakerMeetingsEventsPoc.filter((event) => event.start_date.includes(date));

        const hasMultipleEventsSpeaker = speakerPrevSessionsEventsForDate.length + speakerCompSessionsEventsForDate.length + speakerMeetingsEventsPocForDate.length > 1;

        const hasSingleEventSpeaker = speakerPrevSessionsEventsForDate.length + speakerCompSessionsEventsForDate.length + speakerMeetingsEventsPocForDate.length === 1;

        //Student Events Details
        const studentSpecialEvents = availabilityData?.Student?.special_events || [];
        const studentPrevSessionsEvents = availabilityData?.Student?.sessions_details?.institution_key_notes || [];
        const studentCompSessionsEvents = availabilityData?.Student?.sessions_details?.completed_key_notes || [];
        const studentMeetingsEventsSuccessCoach = availabilityData?.Student?.poc_success_coach_meeting_details || [];

        const studentSpecialEventsForDate = studentSpecialEvents.filter((event) => event.start_date.includes(date));
        const studentPrevSessionsEventsForDate = studentPrevSessionsEvents.filter((event) => event.start_date.includes(date));
        const studentCompSessionsEventsForDate = studentCompSessionsEvents.filter((event) => event.start_date.includes(date));
        const studentMeetingsEventsSuccessCoachForDate = studentMeetingsEventsSuccessCoach.filter((event) => event.start_date.includes(date));

        const hasMultipleEventsStudent =
            studentSpecialEventsForDate.length + studentPrevSessionsEventsForDate.length + studentCompSessionsEventsForDate.length + studentMeetingsEventsSuccessCoachForDate.length > 1;

        const hasSingleEventStudent =
            studentSpecialEventsForDate.length + studentPrevSessionsEventsForDate.length + studentCompSessionsEventsForDate.length + studentMeetingsEventsSuccessCoachForDate.length === 1;

        return (
            <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={
                    <div>
                        {/* POC Events  */}
                        {hasMultipleEventsPoc && (
                            <div
                                style={{
                                    backgroundColor: "#a32349",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}
                        {hasSingleEventPoc && (
                            <div
                                style={{
                                    backgroundColor: "#8794ef",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}

                        {/* Student Events  */}
                        {hasMultipleEventsStudent && (
                            <div
                                style={{
                                    backgroundColor: "#a32349",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}
                        {hasSingleEventStudent && (
                            <div
                                style={{
                                    backgroundColor: "#8794ef",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}

                        {/* Speaker Events  */}
                        {hasMultipleEventsSpeaker && (
                            <div
                                style={{
                                    backgroundColor: "#a32349",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}
                        {hasSingleEventSpeaker && (
                            <div
                                style={{
                                    backgroundColor: "#8794ef",
                                    ...circleStyles,
                                }}
                            ></div>
                        )}
                    </div>
                }
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} onClick={() => handleDateClick(day)} />
            </Badge>
        );
    }

    const filteredEvents = availabilityData[userTypes[userType]]?.special_events?.map(event => {
        const eventDate = dayjs(event.start_date);

        if (
            eventDate.isSame(currentMonth, 'month') &&
            eventDate.isSame(initialValue, 'year')
        ) {
            return {
                title: event.title,
                alternative_meeting_url: event.alternative_meeting_url,
                id: event.id,
                time: eventDate.format('MMMM D, h:mm A')
            };
        }
        return null;
    }).filter(Boolean);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleEventJoin = (id, alternativeUrl) => {
        if (alternativeUrl) {
            window.open(alternativeUrl, '_blank');
        }
        if (!alternativeUrl) {
            navigate("/live-events/" + id);
            scrollToTop();
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between" onClick={toggleCalendar} style={{ padding: "0 10px", cursor: "pointer" }}>
                {/*<h5 className="m-0">Calendar</h5>*/}
                {/*{showCalendar ? (*/}
                {/*    <div className="discussion-toggle">*/}
                {/*        <PiCaretUpBold className="mt-1 fs-5" />*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div className="discussion-toggle">*/}
                {/*        <PiCaretDownBold className="mt-1 fs-5" />*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            {showCalendar && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                            sx={{
                                color: "#fff",
                            }}
                            defaultValue={initialValue}
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderLoading={() => <DayCalendarSkeleton />}
                            slots={{
                                day: ServerDay,
                            }}
                            // className="custom-weekday-labels"
                            showDaysOutsideCurrentMonth
                            // referenceDate={dayjs(new Date())}
                            onMonthChange={(newMonth) => setCurrentMonth(newMonth)}
                        />
                    </DemoContainer>
                    <div className="calendar-footer__live-events-list">
                        {filteredEvents?.length > 0 ? (
                            filteredEvents?.map((item) => {
                                return (
                                    <div className="d-flex flex-column calendar-footer__live-events-list__item">
                                        <span className="fs-6 lh-base fw-normal text-secondary">
                                            {item?.time}
                                        </span>
                                        <span className="fs-5 lh-base fw-semibold text-white cursor-pointer" onClick={() => handleEventJoin(item?.id, item?.alternative_meeting_url)}>
                                            {item?.title}
                                        </span>
                                    </div>
                                );
                            })
                        ) : (
                            <span className="fs-6 lh-base fw-normal text-secondary">
                                There are no events scheduled for this month.
                            </span>
                        )}
                    </div>
                </LocalizationProvider>
            )}
            {isPopupOpen && <ShowAllEventsDetails isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} selectedDate={selectedDate} availabilityData={availabilityData} />}
        </>
    );
};

export default Calendar;
