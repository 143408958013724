import _ from "lodash";

const helpers = {
    isEmpty: value => {
        return _.isEmpty(value) || value === "undefined" || value === "null"
    },
    pluralize: (count, singular, plural) => {
        return count === 1 ? `${count} ${singular}` : `${count} ${plural}`;
    },
}

export default helpers