import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useDownload from "../../../hooks/useDownload";
import {MasterClassService} from "../../../services/masterClassService/masterClassService";
import Loader from "../../../components/common/Loader";
import ProgressBar from "@ramonak/react-progress-bar";
import {IoCheckmark} from "react-icons/io5";
import MasterClassLessonsList from "./MasterClassLessonsList";
import notFound from "../../../assets/not-found/not_found.png";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import MasterClassCompleteInfoModal from "./modals/MasterClassCompleteInfoModal";
import {PageErrorMessage} from "../../../components/PageErrorMessage";
import UserDetails from "../../../components/UserDetails";
import TextEditorContent from "../../../components/TextEditorContent";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";

const InstitutionUserMasterClassDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [masterClassDetails, setMasterClassDetails] = useState(null);
    const { id } = useParams();
    const downloadFile = useDownload();
    const navigate = useNavigate();
    const [showCompletionModal, setShowCompletionModal] = useState(false);
    const [error, setError] = useState(null);

    const masterClassDetailsData = async () => {
        setIsLoading(true);
        try {
            await MasterClassService.getSingleMasterClassData(id).then((response) => {
                setMasterClassDetails(response?.data);

                if (response?.data.all_lessons_completed && !response?.data.is_completed) {
                    setShowCompletionModal(true);
                }
            });

            setIsLoading(false);
        } catch (error) {
            setError({
                statusCode: error.response.status,
                message: error.response.data.message,
            });
            setIsLoading(false);
        }
    };

    // useEffect of call all api function
    useEffect(() => {
        masterClassDetailsData();
    }, [id]);

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                // Remove HTML tags from the text
                const textWithoutTags = part.replace(/(<([^>]+)>)/gi, "");
                return <span key={index}>{textWithoutTags}</span>;
            }
        });
    };

    const handleCompleteConfirmation = () => {
        setShowCompletionModal(true);
    };

    const handleDownloadPlaybook = async () => {
        if (masterClassDetails.playbook_pdf) {
            const filename = (masterClassDetails?.name || "playbook_file").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            await downloadFile(masterClassDetails.playbook_pdf, filename);
        }
    };

    const handleCloseCompletionModal = () => {
        setShowCompletionModal(false);
    };

    if (error) {
        return <PageErrorMessage
            error={error}
            entityTitle="Masterclass"
        />;
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Loader/>
                </>
            ) : (
                <>
                    <div className="container m-4">
                        <div className="row">
                            {masterClassDetails?.program &&
                                <CustomBreadcrumbs
                                    currentPageTitle={masterClassDetails?.name}
                                    pages={[
                                        {
                                            title: masterClassDetails?.program?.title,
                                            to: `/programs/${masterClassDetails?.program?.id}`
                                        },
                                    ]}
                                />
                            }
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h1>{masterClassDetails?.name}</h1>
                                <TextEditorContent
                                    text={masterClassDetails?.description}
                                />
                                <div className="d-flex mt-3">
                                    {masterClassDetails?.playbook_pdf &&
                                        <button className="btn btn-primary me-2"
                                                disabled={!masterClassDetails?.playbook_pdf}
                                                onClick={handleDownloadPlaybook}>Download Playbook PDF</button>
                                    }
                                    <button
                                        className="btn btn-primary-info"
                                        onClick={() => navigate('/lessons/' + masterClassDetails?.first_lesson)}
                                        disabled={masterClassDetails?.first_lesson === null}
                                    >
                                        Start Watching
                                    </button>
                                </div>
                                <div className="mt-3">
                                    <div className="fs-6 lh-base fw-bolder">
                                        Masterclass Progress
                                    </div>
                                    <ProgressBar completed={masterClassDetails?.progress} bgColor="#1c64f2"
                                                 baseBgColor="#374151"
                                                 className={`w-100 progressbar-count ${masterClassDetails?.progress > 5 ? '' : 'progressbar-count__hide-value'}`}/>
                                </div>
                                {!masterClassDetails?.is_completed &&
                                    <button className="btn btn-success mt-3" onClick={handleCompleteConfirmation}>Complete</button>
                                }
                                {masterClassDetails?.is_completed &&
                                    <button className="btn btn-secondary d-flex align-center mt-3" disabled={true}>
                                        Completed <IoCheckmark/>
                                    </button>
                                }
                            </div>

                            <div className="col-md-6 text-center">
                                <div className="master-class__image">
                                    <img
                                        src={masterClassDetails?.cover_image}
                                        alt={masterClassDetails?.name}
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="master-class__speaker-info p-3 mt-3">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div onClick={() => navigate(`/community/users/${masterClassDetails?.speaker.id}`)}>
                                            <UserDetails
                                                user={masterClassDetails?.speaker}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            {
                                                masterClassDetails?.speaker?.bio && renderAnswer(masterClassDetails?.speaker?.bio)?.map((element, index) =>
                                                    <React.Fragment key={index}>{element}</React.Fragment>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {masterClassDetails?.lessons.length > 0 &&
                                    <MasterClassLessonsList
                                        lessons={masterClassDetails?.lessons}
                                    />
                                }
                                {masterClassDetails?.lessons.length === 0 &&
                                    <div
                                        className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                        style={{height: "500px"}}>
                                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
                                        <h3>No Lessons Added</h3>
                                        <p className="fs-5">No lessons available at the moment.</p>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="master-class__comments">
                                    <DiscussionsCommentList
                                        path={`master-classes/${masterClassDetails?.id}`}
                                        relatedTitlesToDisplay={['lessons']}
                                        highlightingCompareId={masterClassDetails?.speaker.id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showCompletionModal &&
                        <MasterClassCompleteInfoModal
                            handleClose={handleCloseCompletionModal}
                            masterClass={masterClassDetails}
                            onUpdateMasterClass={(updatedMasterClass) => setMasterClassDetails(updatedMasterClass)}
                        />
                    }
                </>
            )}
        </>
    );
};

export default InstitutionUserMasterClassDetails;