/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import dayjs from "dayjs";
import moment from "moment";
import convertUtcToSelectedTimeZone from "../../../utils/common-functions/ConvertUtcToTimezone";
import notFound from "../../../assets/not-found/not_found.png";

const ShowAllEventsDetails = ({ isPopupOpen, setIsPopupOpen, selectedDate, availabilityData }) => {
    const formattedDate = dayjs(selectedDate).format("MMMM DD, YYYY");

    const formattedStartDate = (item) => {
        const utcStartDate = item?.start_date;
        const localStartDate = convertUtcToSelectedTimeZone(utcStartDate);
        return moment(localStartDate).format("hh:mm A");
    };

    const formattedEndDate = (item) => {
        const utcStartDate = item?.end_date;
        const localStartDate = convertUtcToSelectedTimeZone(utcStartDate);
        return moment(localStartDate).format("hh:mm A");
    };

    //Poc Events Details
    const pocPrevSessionsEvents = availabilityData?.POC?.sessions_details?.institution_key_notes || [];
    const pocCompSessionsEvents = availabilityData?.POC?.sessions_details?.completed_key_notes || [];
    const pocMeetingsEventsSpeaker = availabilityData?.POC?.poc_speaker_meeting_details || [];
    const pocMeetingsEventsSuccessCoach = availabilityData?.POC?.poc_success_coach_meting_details || [];
    const pocSpecialEvents = availabilityData?.POC?.special_events || [];

    const pocPrevSessionsEventsForDate = pocPrevSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const pocCompSessionsEventsForDate = pocCompSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const pocMeetingsEventsSpeakerForDate = pocMeetingsEventsSpeaker.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const pocMeetingsEventsSuccessCoachForDate = pocMeetingsEventsSuccessCoach.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const pocSpecialEventsForDate = pocSpecialEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });

    //Speaker Events Details
    const speakerPrevSessionsEvents = availabilityData?.Speaker?.sessions_details?.institution_key_notes || [];
    const speakerCompSessionsEvents = availabilityData?.Speaker?.sessions_details?.completed_key_notes || [];
    const speakerMeetingsEventsPoc = availabilityData?.Speaker?.speaker_availability?.booked || [];

    const speakerPrevSessionsEventsForDate = speakerPrevSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const speakerCompSessionsEventsForDate = speakerCompSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const speakerMeetingsEventsPocForDate = speakerMeetingsEventsPoc.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });

    //Student Events Details
    const studentPrevSessionsEvents = availabilityData?.Student?.sessions_details?.institution_key_notes || [];
    const studentCompSessionsEvents = availabilityData?.Student?.sessions_details?.completed_key_notes || [];
    const studentMeetingsEventsSuccessCoach = availabilityData?.Student?.poc_success_coach_meeting_details || [];
    const studentSpecialEvents = availabilityData?.Student?.special_events || [];

    const studentPrevSessionsEventsForDate = studentPrevSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const studentCompSessionsEventsForDate = studentCompSessionsEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const studentMeetingsEventsSuccessCoachForDate = studentMeetingsEventsSuccessCoach.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });
    const studentSpecialEventsForDate = studentSpecialEvents.filter((event) => {
        return dayjs(event?.start_date).format("MMMM DD, YYYY") === formattedDate;
    });

    const handleClose = () => {
        setIsPopupOpen(false);
    };

    return (
        <>
            <Modal onHide={handleClose} show={isPopupOpen} className="calendar-all-events-modal">
                <Modal.Header>
                    <h5>
                        All Events of&nbsp;
                        {formattedDate}
                    </h5>
                    <a className="tu-close " type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <AiOutlineClose fontSize={24} style={{ color: "black" }} />
                    </a>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
                    {pocCompSessionsEventsForDate?.length > 0 ||
                    pocMeetingsEventsSpeakerForDate?.length > 0 ||
                    pocPrevSessionsEventsForDate?.length > 0 ||
                    pocMeetingsEventsSuccessCoachForDate?.length > 0 ||
                    pocSpecialEventsForDate?.length > 0 ||
                    studentPrevSessionsEventsForDate?.length > 0 ||
                    studentCompSessionsEventsForDate?.length > 0 ||
                    studentMeetingsEventsSuccessCoachForDate?.length > 0 ||
                    studentSpecialEventsForDate?.length > 0 ||
                    speakerPrevSessionsEventsForDate?.length > 0 ||
                    speakerCompSessionsEventsForDate?.length > 0 ||
                    speakerMeetingsEventsPocForDate?.length > 0 ? (
                        <>
                            {/* POC Events Start */}
                            {/* POC Completed Sessions  */}
                            {pocCompSessionsEventsForDate?.length > 0 &&
                                pocCompSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 rounded-4 completed-session-event mb-3" key={index}>
                                        <h4>Completed Session Details</h4>
                                        <div className="d-flex flex-wrap ">
                                            <span className="m-0 fw-bold">Keynote : </span>
                                            <span className="ps-2">{item?.key_note?.name}</span>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <span className="m-0 fw-bold">Masterclass : </span>
                                            <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <span className="m-0 fw-bold">Time : </span>
                                            <span className="ps-2">
                                                {formattedStartDate(item)} - {formattedEndDate(item)}
                                            </span>
                                        </div>
                                    </div>
                                ))}

                            {/* POC - Speaker Meetings Details  */}
                            {pocMeetingsEventsSpeakerForDate?.length > 0 &&
                                pocMeetingsEventsSpeakerForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 speaker-meeting-event mb-3 " key={index}>
                                        <div className="">
                                            <h4 className="">Speaker Meeting Details</h4>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Speaker Name : </span>
                                                <span className="ps-2">
                                                    {item?.institute_key_notes?.speaker?.first_name}&nbsp;{item?.institute_key_notes?.speaker?.last_name}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.institute_key_notes?.key_note?.name}</span>
                                            </div>

                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* POC Previous Sessions  */}
                            {pocPrevSessionsEventsForDate?.length > 0 &&
                                pocPrevSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 previous-session-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Upcoming Session Details</h4>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.key_note?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* POC - Success Coach Meetings Details  */}
                            {pocMeetingsEventsSuccessCoachForDate?.length > 0 &&
                                pocMeetingsEventsSuccessCoachForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 coach-meeting-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Success Coach Meeting Details</h4>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Coach Name : </span>
                                                <span className="ps-2">
                                                    {item?.success_coach_availability?.success_coach?.first_name}&nbsp;{item?.success_coach_availability?.success_coach?.last_name}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-wrap ">
                                                <span className=" fw-bold m-0">Masterclass : </span>
                                                <span className="ps-2">{item?.success_coach_availability?.master_class?.name}</span>
                                            </div>

                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* POC Special Events */}
                            {pocSpecialEventsForDate?.length > 0 &&
                                pocSpecialEventsForDate?.map((item) => {
                                    return (
                                        <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 previous-session-event mb-3" key={item?.id}>
                                            <h4 className="">Live Event Details</h4>
                                            <div className="d-flex flex-wrap mt-3">
                                                <span className="m-0 fw-bold">Live event : </span>
                                                <span className="ps-2">{item?.title}</span>
                                            </div>
                                            <div className="d-flex flex-wrap mt-1">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}

                            {/* POC Events End  */}
                            {/* Student Events Start */}
                            {/* Student Previous Sessions */}
                            {studentPrevSessionsEventsForDate?.length > 0 &&
                                studentPrevSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 previous-session-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Upcoming Session Details</h4>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.key_note?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Student Completed Sessions */}
                            {studentCompSessionsEventsForDate?.length > 0 &&
                                studentCompSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 rounded-4 completed-session-event mb-3">
                                        <div className="" key={index}>
                                            <h4 className="">Completed Session Details</h4>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.key_note?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Student - Success Coach meetings details  */}
                            {studentMeetingsEventsSuccessCoachForDate?.length > 0 &&
                                studentMeetingsEventsSuccessCoachForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 coach-meeting-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Success Coach Meeting Details</h4>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Coach Name : </span>
                                                <span className="ps-2">
                                                    {item?.success_coach?.first_name}&nbsp;{item?.success_coach?.last_name}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.master_class?.name}</span>
                                            </div>

                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Student Special Events */}
                            {studentSpecialEventsForDate?.length > 0 &&
                                studentSpecialEventsForDate?.map((item) => {
                                    return (
                                        <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 previous-session-event mb-3" key={item?.id}>
                                            <h4 className="">Live Event Details</h4>
                                            <div className="d-flex flex-wrap mt-3">
                                                <span className="m-0 fw-bold">Live event : </span>
                                                <span className="ps-2">{item?.title}</span>
                                            </div>
                                            <div className="d-flex flex-wrap mt-1">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}

                            {/* Student Events End  */}
                            {/* Speaker Events Start */}
                            {/* Speaker Previous Sessions */}
                            {speakerPrevSessionsEventsForDate?.length > 0 &&
                                speakerPrevSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 previous-session-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Upcoming Session Details</h4>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.key_note?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Speaker Completed Sessions */}
                            {speakerCompSessionsEventsForDate?.length > 0 &&
                                speakerCompSessionsEventsForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 rounded-4 completed-session-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Completed Session Details</h4>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2 ">{item?.key_note?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Masterclass : </span>
                                                <span className="ps-2">{item?.key_note?.master_class?.name}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {/* Speaker - POC Meetings Details  */}
                            {speakerMeetingsEventsPocForDate?.length > 0 &&
                                speakerMeetingsEventsPocForDate?.map((item, index) => (
                                    <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 speaker-meeting-event mb-3" key={index}>
                                        <div className="">
                                            <h4 className="">Speaker Meeting Details</h4>
                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Speaker Name : </span>
                                                <span className="ps-2">
                                                    {item?.institute_key_notes?.speaker?.first_name}&nbsp;{item?.institute_key_notes?.speaker?.last_name}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-wrap ">
                                                <span className="m-0 fw-bold">Keynote : </span>
                                                <span className="ps-2">{item?.institute_key_notes?.key_note?.name}</span>
                                            </div>

                                            <div className="d-flex flex-wrap">
                                                <span className="m-0 fw-bold">Time : </span>
                                                <span className="ps-2">
                                                    {formattedStartDate(item)} - {formattedEndDate(item)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {/* Speaker Events End  */}
                        </>
                    ) : (
                        <>
                            <div className="w-100 border-bottom shadow p-3 mt-3 rounded-4 text-center">
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h5>No Meetings or Sessions for Date</h5>
                                <p>There are no meetings or sessions scheduled for the selected date. Please check another date.</p>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShowAllEventsDetails;
